'use client'

import * as SeparatorPrimitive from '@radix-ui/react-separator'
import * as React from 'react'

import { cn } from '@/_lib/utils'
import { cva, VariantProps } from 'class-variance-authority'

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = 'horizontal', decorative = true, ...props },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        'shrink-0 bg-border',
        orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
        className
      )}
      {...props}
    />
  )
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export const separatorWithTextVariants = cva('', {
  variants: {
    size: {
      default: 'text-sm',
      large: 'text-lg',
      small: 'text-sm',
      xsmall: 'text-xs',
      base: 'text-base'
    },
    bold: {
      true: 'font-bold',
      false: ''
    },
    left: {
      true: '',
      false: 'flex-0'
    },
    right: {
      true: '',
      false: 'flex-0'
    }
  },
  defaultVariants: {
    size: 'default',
    bold: false,
    left: true,
    right: true
  }
})

interface SeparatorWithTextProps
  extends VariantProps<typeof separatorWithTextVariants>,
    React.HTMLAttributes<HTMLDivElement> {}

const SeparatorWithText = ({
  children,
  className,
  left = true,
  right = true,
  size,
  bold,
  ...props
}: SeparatorWithTextProps) => (
  <div
    className={cn(
      'flex items-center gap-4',
      separatorWithTextVariants({
        bold,
        size,
        className
      })
    )}
    {...props}
  >
    {left && <Separator className="flex-1 border-t" />}
    <p className="text-muted-foreground">{children}</p>
    {right && <Separator className="flex-1 border-t" />}
  </div>
)

export { Separator, SeparatorWithText }
