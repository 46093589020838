// UI
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from './form'
import { Input } from './input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from './select'
// Config
import { COUNTRIES } from '@/_config/countries'
// Form
import { Control, FieldValues, useFormContext } from 'react-hook-form'
// Utils
import { cn } from '@/_lib/utils'

interface Props<T extends FieldValues> {
  containerClassName?: string
  labelsClassName?: string
  prefixClassName?: string
  control: Control<T>
  optional?: boolean
}

export const PhoneInput = <T extends FieldValues>({
  containerClassName,
  labelsClassName,
  prefixClassName,
  optional
}: Props<T>) => {
  const { control } = useFormContext<{
    prefix: string
    phone: string
  }>()

  return (
    <div className={cn(containerClassName)}>
      <FormField
        control={control}
        name="prefix"
        render={({ field: { onChange, onBlur, value } }) => (
          <FormItem className={cn('w-fit', prefixClassName)}>
            <FormLabel optional={optional} className={cn(labelsClassName)}>
              Phone prefix
            </FormLabel>

            <Select
              onValueChange={(value) => {
                onChange(value)
                onBlur()
              }}
              defaultValue={value}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
              </FormControl>

              <SelectContent className="h-72">
                {COUNTRIES.map((country) => (
                  <SelectItem
                    key={country.code}
                    value={country.prefix.replace('+', '')}
                  >
                    <div className="flex items-center">
                      <span className="ml-2 hidden sm:block">
                        {country.name}
                      </span>

                      <span className="font-bold ml-1">({country.prefix})</span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="phone"
        render={({ field }) => (
          <FormItem className="flex-1">
            <FormLabel optional={optional} className={cn(labelsClassName)}>
              Phone number
            </FormLabel>

            <FormControl>
              <Input
                type="tel"
                {...field}
                placeholder="Type user's phone number..."
              />
            </FormControl>

            <FormMessage className="text-danger-foreground" />
          </FormItem>
        )}
      />
    </div>
  )
}
