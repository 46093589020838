// External
import { AVAILABLE_LANGUAGES } from '@/_utils'
import { type Locale } from 'date-fns'
import {
  ar,
  ca,
  de,
  enGB,
  enUS,
  es,
  eu,
  fr,
  hr,
  hu,
  it,
  pl,
  ro,
  pt,
  sv
} from 'date-fns/locale'
// Models

type EXTRA_LANGUAGES = 'es-ES'

export type LOCALE_MAP_LANGUAGES = AVAILABLE_LANGUAGES & EXTRA_LANGUAGES

export const formatDateLocaleMap: Record<LOCALE_MAP_LANGUAGES, Locale> = {
  ar,
  ca,
  de,
  'es-ES': es,
  'en-GB': enGB,
  'en-US': enUS,
  es,
  eu,
  fr,
  hr,
  hu,
  it,
  pt,
  pl,
  sv,
  ro
}
