import { DropdownCSVImportKeys } from '@/_models'
import * as XLSX from 'xlsx'

interface Params {
  file: File
  keys: DropdownCSVImportKeys
}

export const readSpreadsheetData = async ({ file, keys }: Params) => {
  try {
    let rows: string[] = []

    if (file.name.endsWith('.csv')) {
      const text = await file.text()
      rows = text.split('\n')
    } else if (file.name.endsWith('.xlsx')) {
      const arrayBuffer = await file.arrayBuffer()
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' })
      const sheet = workbook.Sheets[workbook.SheetNames[0]]
      rows = XLSX.utils.sheet_to_csv(sheet).split('\n')
    }

    const headerRow = rows[0].split(',').map((column) => column.trim())
    const columnNames = Object.values(keys).map((value) => value.column)

    const unexpectedColumns = headerRow.filter(
      (column) => !columnNames.includes(column)
    )
    if (unexpectedColumns.length > 0) {
      throw new Error(
        `Invalid columns found in file: ${unexpectedColumns.join(', ')}`
      )
    }

    const missingColumns = columnNames.filter(
      (column) => !headerRow.includes(column)
    )
    if (missingColumns.length > 0) {
      throw new Error(`Missing columns in file: ${missingColumns.join(', ')}`)
    }

    const newData = rows
      .slice(1)
      .map((row) => {
        const columns = row.split(',')

        if (columns.length !== headerRow.length) {
          return null
        }

        const rowData: Record<string, string | number> = {}

        for (const [key, value] of Object.entries(keys)) {
          const columnIndex = headerRow.findIndex(
            (column) => column === value.column
          )

          if (columnIndex !== -1) {
            const value = columns[columnIndex].trim()
            rowData[key] = String(value)
          }
        }

        return rowData
      })
      .filter(Boolean)

    if (newData.length === 0) {
      throw new Error('CSV file is empty')
    }

    if (newData.length > 100) {
      throw new Error(
        'CSV file is too large. Please upload a file with 100 or less documents'
      )
    }

    return newData
  } catch (error) {
    return await Promise.reject(error)
  }
}
