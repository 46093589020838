import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/_lib/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        primary: 'primary-button-gradient text-primary-foreground',
        destructive:
          'border-transparent bg-danger text-danger-foreground hover:bg-danger/80',
        ghost: 'border-transparent bg-none hover:bg-muted/80',
        outline: 'text-foreground',
        muted: 'bg-muted text-muted-foreground border-muted',
        'muted-dark': 'text-gray-500 bg-gray-200',
        success:
          'bg-success/80 border-success-foreground text-success-foreground',
        warning:
          'bg-warning/80 border-warning-foreground text-warning-foreground'
      },
      uppercase: {
        true: 'uppercase',
        false: ''
      },
      size: {
        default: 'text-xs',
        sm: 'text-xs',
        md: 'text-sm',
        lg: 'text-base'
      },
      rounded: {
        default: 'rounded-md',
        none: 'rounded-none',
        sm: 'rounded-sm',
        md: 'rounded-md',
        lg: 'rounded-lg'
      },
      full: {
        true: 'w-full text-center',
        false: ''
      },
      tracking: {
        default: 'tracking-normal',
        widest: 'tracking-widest',
        wide: 'tracking-wide',
        wider: 'tracking-wider',
        normal: 'tracking-normal',
        tighter: 'tracking-tighter',
        tight: 'tracking-tight'
      }
    },
    defaultVariants: {
      variant: 'default',
      tracking: 'default',
      rounded: 'default',
      full: false,
      size: 'default'
    }
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({
  className,
  uppercase,
  tracking,
  rounded,
  variant,
  full,
  size,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(
        badgeVariants({ full, uppercase, tracking, rounded, variant, size }),
        className
      )}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
