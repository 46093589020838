import { cn } from '@/_lib/utils'
import { cva, VariantProps } from 'class-variance-authority'
import { FC, PropsWithChildren } from 'react'

const emptyViewVariants = cva(
  'flex flex-1 flex-col items-center justify-center',
  {
    variants: {
      variant: {
        error: 'border-red-500',
        default: 'border-muted'
      },
      shadow: {
        true: 'shadow-sm',
        false: ''
      },
      rounded: {
        true: 'rounded-lg',
        false: ''
      },
      border: {
        true: 'border border-dashed',
        false: ''
      },
      center: {
        true: 'flex items-center justify-center'
      }
    },
    defaultVariants: {
      variant: 'default',
      center: true,
      border: true,
      rounded: true,
      shadow: true
    }
  }
)

interface EmptyViewProps extends VariantProps<typeof emptyViewVariants> {
  className?: string
}

export const EmptyView: FC<EmptyViewProps & PropsWithChildren> = ({
  className,
  variant,
  shadow,
  center,
  rounded,
  children
}) => {
  return (
    <div
      className={cn(
        emptyViewVariants({ variant, shadow, center, rounded, className })
      )}
    >
      {children}
    </div>
  )
}

export const EmptyViewTitle: FC<PropsWithChildren> = ({ children }) => (
  <h3 className="text-2xl font-bold tracking-tight">{children}</h3>
)

export const EmptyViewDescription: FC<PropsWithChildren> = ({ children }) => (
  <p className="text-sm text-muted-foreground">{children}</p>
)
