// React
import React from 'react'
// utils
import { cn } from '@/_lib/utils'
// cva
import { cva, VariantProps } from 'class-variance-authority'

const informationListVariants = cva(
  '[&>li:not(:first-child)]:py-1 [&>li:is(:first-child)]:pb-1',
  {
    variants: {
      divider: {
        true: 'divide-y-[1px] divide-gray-200'
      },
      listStyle: {
        none: 'none',
        default: 'none',
        disc: 'list-disc',
        decimal: 'list-decimal'
      },
      listPosition: {
        default: 'list-inside',
        outside: 'list-outside',
        inside: 'list-inside'
      }
    },
    defaultVariants: {
      divider: false,
      listStyle: 'default',
      listPosition: 'default'
    }
  }
)

const InformationList = React.forwardRef<
  React.ElementRef<'ul'>,
  React.ComponentPropsWithoutRef<'ul'> &
    VariantProps<typeof informationListVariants>
>(({ className, divider, listStyle, listPosition, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn(informationListVariants({ divider, listStyle, listPosition }), className)}
    {...props}
  />
))
InformationList.displayName = 'SheetSectionList'

const informationListItem = cva('group [span:nth-child(2)]:font-bold', {
  variants: {
    flex: {
      true: 'flex items-center justify-between',
      false: ''
    }
  },
  defaultVariants: {
    flex: true
  }
})

const InformationListItem = React.forwardRef<
  React.ElementRef<'li'>,
  React.ComponentPropsWithoutRef<'li'> &
    VariantProps<typeof informationListItem>
>(({ className, title, value, flex, ...props }, ref) => (
  <li
    ref={ref}
    {...props}
    className={cn(informationListItem({ flex }), className)}
  />
))
InformationListItem.displayName = 'InformationListItem'

export { InformationList, InformationListItem }
