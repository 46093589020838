// External
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
// Constants
import { formatDateLocaleMap } from '@/_constants'

export const formatDate = ({
  date,
  formatString,
  timezone
}: {
  date: Date
  formatString: string
  timezone?: string
}) => {
  const locale: string = navigator.language

  const formatOptions = {
    locale: formatDateLocaleMap[locale as keyof typeof formatDateLocaleMap]
  }

  if (timezone !== undefined) {
    return formatInTimeZone(new Date(date), timezone, formatString, {
      ...formatOptions,
      timeZone: timezone
    })
  }

  return format(new Date(date), formatString, formatOptions)
}
