export const DEFAULT_LANGUAGE = 'en-GB'

export type AVAILABLE_LANGUAGES =
  | 'en-GB'
  | 'en-US'
  | 'es'
  | 'hu'
  | 'ro'
  | 'pl'
  | 'pt'
  | 'sv'
  | 'it'
  | 'fr'
  | 'ar'
  | 'hr'
  | 'ca'
  | 'eu'
  | 'sv'
  | 'de'

export const LANGUAGES: Array<AVAILABLE_LANGUAGES> = [
  'en-GB',
  'en-US',
  'es',
  'hu',
  'ro',
  'pl',
  'pt',
  'sv',
  'it',
  'fr',
  'ar',
  'hr',
  'ca',
  'eu',
  'sv',
  'de'
]

export type LANGUAGE_METADATA = {
  flag: string
  name: string
  isoCode: AVAILABLE_LANGUAGES
}

export const LANGUAGES_MAP: Array<LANGUAGE_METADATA> = [
  {
    flag: '🇬🇧🇺🇸',
    name: 'English',
    isoCode: 'en-GB'
  },
  {
    flag: '🇪🇸',
    name: 'Spanish',
    isoCode: 'es'
  },
  {
    flag: '🇪🇸',
    name: 'Catalan',
    isoCode: 'ca'
  },
  {
    flag: '🇪🇸',
    name: 'Basque',
    isoCode: 'eu'
  },
  {
    flag: '🇭🇺',
    name: 'Hungarian',
    isoCode: 'hu'
  },
  {
    flag: '🇷🇴',
    name: 'Romanian',
    isoCode: 'ro'
  },
  {
    flag: '🇵🇱',
    name: 'Polski',
    isoCode: 'pl'
  },
  {
    flag: '🇵🇹',
    name: 'Portugues',
    isoCode: 'pt'
  },
  {
    flag: '🇸🇪',
    name: 'Swedish',
    isoCode: 'sv'
  },
  {
    flag: '🇮🇹',
    name: 'Italian',
    isoCode: 'it'
  },
  {
    flag: '🇫🇷',
    name: 'French',
    isoCode: 'fr'
  },
  {
    flag: '🇦🇪',
    name: 'Arab',
    isoCode: 'ar'
  },
  {
    flag: '🇭🇷',
    name: 'Croatian',
    isoCode: 'hr'
  },
  {
    flag: '🇩🇪',
    name: 'Deutsch',
    isoCode: 'de'
  }
]
