'use client'
// React
import { ChangeEvent, useEffect, useRef, useState } from 'react'
// UI
import { Icons } from './icons'
import { Input } from './input'
// Utils
import { cn } from '@/_lib/utils'

interface Props {
  className?: string
  placeholder: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const Searchbar = ({ className, placeholder, value, onChange }: Props) => {
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false)
  const searchBarRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!searchBarRef.current) {
      return
    }

    // handle focus event
    searchBarRef.current.addEventListener('focusin', () => {
      setIsSearchBarFocused(true)
    })

    // handle focus out event
    searchBarRef.current.addEventListener('focusout', () => {
      setIsSearchBarFocused(false)
    })

    return () => {
      searchBarRef.current?.removeEventListener('focusin', () => {
        setIsSearchBarFocused(true)
      })

      searchBarRef.current?.removeEventListener('focusout', () => {
        setIsSearchBarFocused(false)
      })
    }
  }, [searchBarRef])

  return (
    <div
      className={cn('z-10 w-full relative', className, {
        'bg-grey-1': isSearchBarFocused
      })}
    >
      <Icons.SearchIcon
        className={cn(
          'absolute transition translate-x-2 translate-y-1/2 h-5 w-5 text-primary',
          {
            'opacity-100': !isSearchBarFocused,
            'opacity-0': isSearchBarFocused
          }
        )}
      />

      <Input
        ref={searchBarRef}
        onFocus={() => setIsSearchBarFocused(true)}
        type="search"
        className={cn('transition', {
          'pl-8': !isSearchBarFocused,
          'pl-4': isSearchBarFocused
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export { Searchbar }
