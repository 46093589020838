'use client'

import * as AvatarPrimitive from '@radix-ui/react-avatar'
import * as React from 'react'

import { cn } from '@/_lib/utils'
import { cva, VariantProps } from 'class-variance-authority'

const sizeVariant = {
  size: {
    default: 'h-12 w-12',
    xsmall: 'h-8 w-8',
    small: 'h-12 w-12',
    medium: 'h-20 w-20',
    large: 'h-24 w-24',
    xlarge: 'h-32 w-32',
    xxlarge: 'h-40 w-40'
  }
}

export const avatarVariants = cva(
  'pointer-events-none select-none relative flex h-12 w-12 shrink-0 overflow-hidden',
  {
    variants: {
      size: {
        default: 'h-12 w-12',
        xxsmall: 'h-6 w-6',
        xsmall: 'h-8 w-8',
        small: 'h-12 w-12',
        medium: 'h-20 w-20',
        large: 'h-24 w-24',
        xlarge: 'h-32 w-32',
        xxlarge: 'h-40 w-40'
      },
      roundness: {
        default: 'rounded-full',
        full: 'rounded-full',
        square: 'rounded-[30px]'
      },
      status: {
        default: 'bg-gray-200',
        online: 'bg-green-500',
        offline: 'bg-red-500',
        away: 'bg-yellow-500'
      },
      shadow: {
        none: '',
        default: 'shadow-sm',
        small: 'shadow-lg',
        large: 'shadow-lg',
        xlarge: 'shadow-xl',
        xxlarge: 'shadow-2xl'
      }
    },
    defaultVariants: {
      size: 'default',
      roundness: 'default',
      status: 'default',
      shadow: 'default'
    }
  }
)

interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarVariants> {}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, roundness, size, shadow, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarVariants({ roundness, size, shadow }), className)}
    {...props}
  />
))
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, src, sizes, ...props }, ref) => {
  const buildAvatarSrc = (src?: string) => {
    if (!src) return

    const url = new URL(src)

    url.searchParams.set('h', '100')
    url.searchParams.set('fit', 'fill')
    url.searchParams.set('bg', '#E5E5EA')
    url.searchParams.set('auto', 'compress,format')

    return url.toString()
  }

  return (
    <AvatarPrimitive.Image
      ref={ref}
      className={cn(
        'aspect-square rounded-[inherit] object-cover h-full w-full',
        className
      )}
      src={buildAvatarSrc(src)}
      {...props}
    />
  )
})
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex h-full uppercase font-semibold w-full items-center justify-center rounded-[inherit] bg-muted',
      className
    )}
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

const avatarBadgeVariants = cva(
  'absolute w-4 h-4 rounded-full bg-background flex items-stretch justify-stretch [&>*]:grow [&>*]:rounded-full',
  {
    variants: {
      position: {
        bottomLeft: 'bottom-0 -left-1',
        bottomRight: 'bottom-0 -right-1',
        topLeft: 'top-0 -left-1',
        topRight: 'top-0 -right-1'
      }
    },
    defaultVariants: {
      position: 'bottomLeft'
    }
  }
)

export interface AvatarBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof avatarBadgeVariants> {
  children?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | null
    | never[]
}

const AvatarBadge = ({ className, position, ...props }: AvatarBadgeProps) => (
  <div
    className={cn(avatarBadgeVariants({ position }), className)}
    {...props}
  />
)

type AvatarGroupContextValue = {
  count?: number
  limit?: number
  setCount?: React.Dispatch<React.SetStateAction<number>>
}

const AvatarGroupContext = React.createContext<AvatarGroupContextValue>({})

const AvatarGroupProvider = ({
  children,
  limit
}: {
  children?: React.ReactNode
  limit?: number
}) => {
  const [count, setCount] = React.useState<number>(0)

  return (
    <AvatarGroupContext.Provider
      value={{
        count,
        setCount,
        limit
      }}
    >
      {children}
    </AvatarGroupContext.Provider>
  )
}

const useAvatarGroupContext = () => React.useContext(AvatarGroupContext)

const avatarGroupVariants = cva('flex items-center justify-end relative', {
  variants: {
    spacing: {
      default: '-space-x-4',
      small: '-space-x-6',
      large: '-space-x-3',
      medium: '-space-x-4'
    }
  },
  defaultVariants: {
    spacing: 'default'
  }
})
export interface AvatarGroupProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof avatarGroupVariants> {
  limit?: number
}

const AvatarGroup = React.forwardRef<HTMLDivElement, AvatarGroupProps>(
  ({ children, className, limit, spacing, ...props }, ref) => {
    return (
      <AvatarGroupProvider limit={limit}>
        <div
          ref={ref}
          className={cn(avatarGroupVariants({ spacing }), className)}
          {...props}
        >
          {children}
        </div>
      </AvatarGroupProvider>
    )
  }
)
AvatarGroup.displayName = 'AvatarGroup'

const AvatarGroupList = ({ children }: { children?: React.ReactNode }) => {
  const { limit, setCount } = useAvatarGroupContext()

  setCount?.(React.Children.count(children))

  return (
    <>{limit ? React.Children.toArray(children).slice(0, limit) : children}</>
  )
}

const avatarOverflowIndicator = cva(
  'relative flex h-10 w-10 shrink-0 items-center justify-center rounded-full',
  {
    variants: {
      ...sizeVariant,
      variant: {
        default: 'bg-primary-foreground text-primary-background',
        ['primary-gradient']: 'primary-button-gradient text-primary-foreground',
        ['secondary-gradient']:
          'secondary-button-gradient text-secondary-foreground'
      },
      border: {
        none: '',
        default: 'border secondary-button-border-gradient'
      }
    },
    defaultVariants: {
      size: 'default',
      variant: 'default',
      border: 'none'
    }
  }
)
export interface AvatarOverflowIndicatorProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof avatarOverflowIndicator> {}

const AvatarOverflowIndicator = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement> & AvatarOverflowIndicatorProps
>(({ className, variant, size, border, ...props }, ref) => {
  const { limit, count } = useAvatarGroupContext()
  if (!limit || !count || count <= limit) return null
  return (
    <span
      ref={ref}
      className={cn(
        avatarOverflowIndicator({ border, variant, size, className })
      )}
      {...props}
    >
      +{count! - limit!}
    </span>
  )
})
AvatarOverflowIndicator.displayName = 'AvatarOverflowIndicator'

export {
  Avatar,
  AvatarBadge,
  AvatarFallback,
  AvatarGroup,
  AvatarGroupList,
  AvatarImage,
  AvatarOverflowIndicator
}
