import { cn } from '@/_lib/utils'
import { cva } from 'class-variance-authority'
import { X } from 'lucide-react'
import { MouseEvent } from 'react'
import { Button } from './button'

const tagVariants = cva(
  'transition-all border inline-flex items-center text-sm pl-2 rounded-md',
  {
    variants: {
      variant: {
        default: 'bg-muted text-muted-foreground hover:bg-muted/80',
        primary:
          'bg-primary border-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive border-destructive text-destructive-foreground hover:bg-destructive/90'
      },
      size: {
        sm: 'text-xs h-7',
        md: 'text-sm h-8',
        lg: 'text-base h-9',
        xl: 'text-lg h-10'
      },
      shape: {
        default: 'rounded-sm',
        rounded: 'rounded-lg',
        square: 'rounded-none',
        pill: 'rounded-full'
      },
      borderStyle: {
        default: 'border-solid',
        none: 'border-none'
      },
      textCase: {
        uppercase: 'uppercase',
        lowercase: 'lowercase',
        capitalize: 'capitalize'
      },
      interaction: {
        clickable: 'cursor-pointer hover:shadow-sm',
        nonClickable: 'cursor-default'
      },
      animation: {
        none: '',
        fadeIn: 'animate-fadeIn',
        slideIn: 'animate-slideIn',
        bounce: 'animate-bounce'
      },
      textStyle: {
        normal: 'font-normal',
        bold: 'font-bold',
        italic: 'italic',
        underline: 'underline',
        lineThrough: 'line-through'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
      shape: 'default',
      borderStyle: 'default',
      textCase: 'capitalize',
      interaction: 'nonClickable',
      animation: 'fadeIn',
      textStyle: 'normal'
    }
  }
)

export interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'primary' | 'destructive'
  size?: 'sm' | 'md' | 'lg' | 'xl'
  shape?: 'default' | 'rounded' | 'square' | 'pill'
  borderStyle?: 'default' | 'none'
  textCase?: 'uppercase' | 'lowercase' | 'capitalize'
  interaction?: 'clickable' | 'nonClickable'
  animation?: 'none' | 'fadeIn' | 'slideIn' | 'bounce'
  textStyle?: 'normal' | 'bold' | 'italic' | 'underline' | 'lineThrough'
  direction?: 'row' | 'column'
  draggable?: boolean
  closable?: boolean
  onClose?: (ev: MouseEvent<HTMLElement>) => void
}

export const Tag = ({ children, ...props }: TagProps) => {
  const {
    variant,
    size,
    shape,
    borderStyle,
    textCase,
    interaction = props.onClick ? 'clickable' : 'nonClickable',
    animation,
    textStyle,
    direction,
    draggable = false,
    closable = false,
    onClose
  } = props

  return (
    <span
      className={cn(
        tagVariants({
          variant,
          size,
          shape,
          borderStyle,
          textCase,
          interaction,
          animation,
          textStyle
        }),
        {
          'justify-between': direction === 'column',
          'cursor-pointer': draggable
        }
      )}
      {...props}
    >
      {children}
      {closable && (
        <Button
          type="button"
          variant="ghost"
          onClick={(e) => {
            onClose?.(e)
          }}
          className={cn('py-1 px-3 h-full hover:bg-transparent')}
        >
          <X size={14} />
        </Button>
      )}
    </span>
  )
}
