import { format, utcToZonedTime } from 'date-fns-tz'

interface Params {
  firstDate: Date
  secondDate: Date
  timezone?: string
}

/**
 * Checks if the second date is after the first date in the specified timezone
 *
 * @param {Date} params.firstDate
 * @param {Date} params.secondDate
 * @param {string} params.timezone
 * @returns {boolean}
 */
export const isAfter = ({ firstDate, secondDate, timezone }: Params) => {
  const zonedDate1 = timezone ? utcToZonedTime(firstDate, timezone) : firstDate
  const zonedDate2 = timezone
    ? utcToZonedTime(secondDate, timezone)
    : secondDate

  const [year1, day1, month1] = format(zonedDate1, 'yyyy d M', {
    timeZone: timezone
  })
    .split(' ')
    .map((x) => parseInt(x))
  const [year2, day2, month2] = format(zonedDate2, 'yyyy d M', {
    timeZone: timezone
  })
    .split(' ')
    .map((x) => parseInt(x))

  if (year2 > year1) {
    return true
  }

  if (
    (year2 > year1 && month2 > month1) ||
    (year2 === year1 && month2 > month1)
  ) {
    return true
  }

  if (year1 === year2 && month2 === month1 && day2 > day1) {
    return true
  }

  return false
}
