'use client'
// External
import { cn } from '@/_lib/utils'
import { formatDate, isAfter } from '@/_utils'
import { cva, VariantProps } from 'class-variance-authority'
import { parseISO } from 'date-fns'
import { ComponentProps, useEffect, useState } from 'react'
// UI
import { Icons } from '@/_components/ui'

const dateCardVariants = cva('', {
  variants: {
    rounded: {
      default: 'rounded-lg',
      sm: 'rounded-md',
      md: 'rounded-md',
      lg: 'rounded-lg'
    },
    full: {
      true: 'w-full'
    }
  },
  defaultVariants: {
    rounded: 'default',
    full: false
  }
})

interface Props
  extends ComponentProps<'div'>,
    VariantProps<typeof dateCardVariants> {
  checkIn?: string | null
  checkOut?: string | null
  formatString?: string
  timezone?: string
  showPlus?: boolean
}

export const DateCard = ({
  checkIn,
  checkOut,
  timezone,
  rounded,
  formatString = 'hh:mm a',
  className,
  showPlus = true,
  ...props
}: Props) => {
  const [checkOutParsed, setCheckOutParsed] = useState(
    formatDate({
      date: checkOut ? new Date(checkOut) : new Date(),
      formatString,
      timezone
    })
  )

  useEffect(() => {
    if (checkOut == null || checkIn == null || !showPlus) {
      return
    }

    const parsedPrevious = parseISO(checkIn)
    const parsedOut = parseISO(checkOut)

    if (
      isAfter({
        firstDate: parsedPrevious,
        secondDate: parsedOut,
        timezone
      })
    ) {
      setCheckOutParsed(
        `${formatDate({
          date: parsedOut,
          timezone,
          formatString
        })} +1`
      )
    }
  }, [checkOut])

  return (
    <div {...props} className={cn(dateCardVariants({ rounded }), className)}>
      <div className="flex items-center gap-x-2 flex-shrink-0 text-sm">
        <Icons.CalendarIcon className="w-4 h-4" />
        <time>
          {checkIn
            ? formatDate({
                date: new Date(String(checkIn)),
                formatString,
                timezone
              })
            : 'N/A'}
        </time>
        <span>-</span>
        <time>{checkOutParsed ? checkOutParsed : 'N/A'}</time>
      </div>
    </div>
  )
}
